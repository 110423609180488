import dynamic from 'next/dynamic';

import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';
import { HeroComplete, HeroSimple } from '@/templates';

import { removeBaseURL } from '@/lib/utils';

const WhyToBuy = dynamic(
  () => import('@/templates/flexible-content/PorqueComprar/PorqueComprar'),
  {
    ssr: true,
  },
);

const WithPaperBorder = dynamic(() => import('@/molecules/with-paper-border'), {
  ssr: true,
});

const SliderHero = dynamic(() => import('@/organisms/slider-hero'), {
  ssr: true,
});

const SliderHeroSlide = dynamic(
  () => import('@/organisms/slider-hero').then((mod) => mod.default.Slide),
  {
    ssr: true,
  },
);

/**
 * HeroSlider
 */
export const HeroSlider = ({ hero }: { hero: any }) => (
  <>
    <ConditionalWrapper
      condition={hero?.papelRasgado?.zona !== 'none'}
      wrapper={(children) => (
        <WithPaperBorder
          border={hero.papelRasgado?.border}
          borderColor={hero.papelRasgado?.borderColor}
          borderHeight={hero.papelRasgado?.borderHeight}
          top={
            hero.papelRasgado?.zona === 'top' ||
            hero.papelRasgado?.zona === 'top bottom'
          }
          bottom={
            hero.papelRasgado?.zona === 'bottom' ||
            hero.papelRasgado?.zona === 'top bottom'
          }
          className="relative z-[1]"
        >
          {children}
        </WithPaperBorder>
      )}
    >
      {hero.mode === 'simple' &&
      hero.slideSimple.length === 1 &&
      hero.slideSimple[0].desktopImage &&
      hero.slideSimple[0].mobileImage ? (
        <ConditionalWrapper
          condition={Boolean(hero.slideSimple[0]?.link?.url)}
          wrapper={(children) => (
            <NextLink
              href={{
                pathname: removeBaseURL(hero.slideSimple[0].link.url ?? ''),
              }}
            >
              <a>{children}</a>
            </NextLink>
          )}
        >
          <HeroSimple
            desktopImage={hero.slideSimple[0].desktopImage}
            mobileImage={hero.slideSimple[0].mobileImage}
            slideIndex={0}
          />
        </ConditionalWrapper>
      ) : hero.mode === 'simple' && hero.slideSimple.length > 1 ? (
        <SliderHero>
          {hero.slideSimple
            .filter((slide: any) => slide.desktopImage && slide.mobileImage)
            .map((slide: any, index: number) => (
              <SliderHeroSlide
                key={index}
                negative={slide.negative}
                caption={slide?.caption}
                className="w-full"
              >
                <ConditionalWrapper
                  condition={Boolean(slide?.link?.url)}
                  wrapper={(children) => (
                    <NextLink
                      href={{
                        pathname: removeBaseURL(slide.link?.url ?? ''),
                      }}
                    >
                      <a>{children}</a>
                    </NextLink>
                  )}
                >
                  <HeroSimple
                    desktopImage={slide.desktopImage}
                    mobileImage={slide.mobileImage}
                    slideIndex={index}
                  />
                </ConditionalWrapper>
              </SliderHeroSlide>
            ))}
        </SliderHero>
      ) : null}

      {hero.mode === 'complete' && hero.slide && hero.slide.length === 1 ? (
        <ConditionalWrapper
          condition={Boolean(hero.slide[0]?.link?.url)}
          wrapper={(children) => (
            <NextLink
              href={{
                pathname: removeBaseURL(hero.slide[0].link?.url ?? ''),
              }}
            >
              <a>{children}</a>
            </NextLink>
          )}
        >
          <HeroComplete
            slide={hero.slide[0]}
            variant={hero.variant}
            slideIndex={0}
          />
        </ConditionalWrapper>
      ) : hero.mode === 'complete' && hero.slide && hero.slide.length > 1 ? (
        <SliderHero>
          {hero.slide.map((slide: any, index: number) => (
            <SliderHeroSlide
              key={index}
              negative={slide.negative}
              caption={slide?.caption}
              className="w-full"
            >
              <ConditionalWrapper
                condition={Boolean(slide?.link?.url)}
                wrapper={(children) => (
                  <NextLink
                    href={{
                      pathname: removeBaseURL(slide.link?.url ?? ''),
                    }}
                  >
                    <a>{children}</a>
                  </NextLink>
                )}
              >
                <HeroComplete
                  slide={slide}
                  variant={hero.variant}
                  slideIndex={index}
                />
              </ConditionalWrapper>
            </SliderHeroSlide>
          ))}
        </SliderHero>
      ) : null}
    </ConditionalWrapper>

    {hero?.showPorqueComprar && <WhyToBuy />}
  </>
);
