import { Buttons, Description, Image, Logo, Pretitle, Title } from './child';
import { Hero } from './Hero';

export type TypeHero = typeof Hero & {
  Pretitle: typeof Pretitle;
  Title: typeof Title;
  Description: typeof Description;
  Buttons: typeof Buttons;
  Image: typeof Image;
  Logo: typeof Logo;
};

(Hero as TypeHero).Pretitle = Pretitle;
(Hero as TypeHero).Title = Title;
(Hero as TypeHero).Description = Description;
(Hero as TypeHero).Buttons = Buttons;
(Hero as TypeHero).Image = Image;
(Hero as TypeHero).Logo = Logo;

Pretitle.displayName = 'Hero.Pretitle';
Title.displayName = 'Hero.Title';
Description.displayName = 'Hero.Description';
Buttons.displayName = 'Hero.Buttons';
Image.displayName = 'Hero.Image';
Logo.displayName = 'Hero.Logo';

export type { TypeHeroProps, TypeButtonsProps } from './types';
export default Hero as TypeHero;
