import { Container } from '@/atoms';

import { toArray } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypeHeroProps } from './types';

/**
 * Hero
 */
export const Hero: FunctionComponent<TypeHeroProps> = ({
  className = '',
  classNameWrapper = '',
  backgroundColor = 'bg-transparent',
  backgroundImage = '',
  overlay = 0,
  negative = false,
  justify = '',
  align = '',
  textAlign = '',
  minHeight,
  padding,
  wrapperSize,
  children,
  priority,
  style,
}: TypeHeroProps) => (
  <Container
    wrapperSize={wrapperSize}
    wrapper
    priority={priority}
    className={`${className}`}
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
    backgroundLazy={true}
    overlay={overlay}
    justify={justify}
    align={align}
    textAlign={textAlign}
    classNameWrapper={classNameWrapper}
    padding={padding}
    style={{
      ...style,
      ...(minHeight
        ? {
            minHeight: `${minHeight}px`,
          }
        : {}),
    }}
  >
    <div
      className={`xl:px-0' flex flex-col gap-y-4 px-4 md:w-full md:max-w-[482px] ${negative ? 'text-typo-alternative' : ''}`}
    >
      {toArray(children).map((child) => child ?? null)}
    </div>
  </Container>
);
